<template>
    <div class="jisuan">
        <van-nav-bar
                title="房贷计算器"
                left-text="返回"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <div class="cal_top">
            <div class="cal_nav">
                <div class="cal_changenav" :class="formData.top == 'benxi' ? 'active_nav' : ''"
                     @click="navClick('benxi')">
                    等额本息
                </div>
                <div class="cal_changenav" :class="formData.top == 'benjin' ? 'active_nav' : ''"
                     @click="navClick('benjin')">
                    等额本金
                </div>
            </div>
            <div class="cal_result displayflex">
                <div class="cal_resultli flexli">
                    <div class="cal_title">参考月供(元)</div>
                    <div class="cal_price cal_price_hook">{{cal_price_hook}}</div>
                    <div class="cal_hktotal">还款总额(元)
                        <span class="htotalnum">{{htotalnum}}</span>
                    </div>
                </div>
                <div class="cal_resultli flexli right_resultli">
                    <div :style="{opacity: formData.top == 'benjin' ? '1' : '0'}">
                        <div class="cal_title cal_dijian">每月递减(元)</div>
                        <div class="cal_price cal_dijianprice">{{cal_dijianprice}}</div>
                    </div>
                    <div class="cal_hktotal cal_right">支付利息(元)
                        <span class="htotallixinum">{{htotallixinum}}</span>
                    </div>
                </div>
            </div>
            <div class="cal_benxiinfo" :style="{opacity: detailShow ? 1 :0}">
                <span class="cal_bxfont" @click="toDetail">
                    {{formData.top == 'benxi' ? '等额本息还款详情 >' : '等额本金还款详情 >'}}
                </span>
            </div>
        </div>
        <van-field
                input-align="right"
                readonly
                name="sdYear"
                :value="formData.type"
                label="贷款类型"
                placeholder="贷款类型"
        ></van-field>
        <!--        <van-field-->
        <!--                input-align="right"-->
        <!--                readonly-->
        <!--                clickable-->
        <!--                name="sdYear"-->
        <!--                :value="formData.type"-->
        <!--                label="贷款类型"-->
        <!--                placeholder="贷款类型"-->
        <!--                @click="showtypePicker = true"-->
        <!--        >-->
        <!--            <template #right-icon>-->
        <!--                <van-icon name="arrow"/>-->
        <!--            </template>-->
        <!--        </van-field>-->
        <!--        <van-popup v-model="showtypePicker" position="bottom">-->
        <!--            <van-picker-->
        <!--                    show-toolbar-->
        <!--                    :columns="typeList"-->
        <!--                    @confirm="typeConfirm"-->
        <!--                    @cancel="showtypePicker = false"-->
        <!--            />-->
        <!--        </van-popup>-->

        <div v-show="formData.type != '公积金贷款'">
            <!--            <h2 class="van-doc-demo-block__title">商业贷款</h2>-->
            <van-field
                    input-align="right"
                    v-model="formData.sdMoney"
                    name="sdMoney"
                    type="number"
                    label="贷款金额"
                    placeholder="贷款金额"
                    @input="sdMoneyChange"
            >
                <template #right-icon>
                    万
                </template>
            </van-field>
            <van-field
                    input-align="right"
                    readonly
                    clickable
                    name="sdYear"
                    :value="formData.sdYear"
                    label="贷款年限"
                    placeholder="贷款年限"
                    @click="showsdYearPicker = true"
            >
                <template #right-icon>
                    <van-icon name="arrow"/>
                </template>
            </van-field>
            <van-popup v-model="showsdYearPicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="loanyear"
                        @confirm="sdYearConfirm"
                        @cancel="showsdYearPicker = false"
                />
            </van-popup>
            <van-field
                    input-align="right"
                    v-model="sdLlText"
                    name="sdLl"
                    type="number"
                    label="贷款利率"
                    placeholder="贷款利率"
                    @input="sdLlChange"
            >
                <template #right-icon>
                    %
                </template>
            </van-field>
<!--            <van-field-->
<!--                    input-align="right"-->
<!--                    readonly-->
<!--                    clickable-->
<!--                    name="sdLl"-->
<!--                    :value="sdLlText"-->
<!--                    label="贷款利率"-->
<!--                    placeholder="贷款利率"-->
<!--                    @click="showsdLlPicker = true"-->
<!--            >-->
<!--                <template #right-icon>-->
<!--                    <van-icon name="arrow"/>-->
<!--                </template>-->
<!--            </van-field>-->
<!--            <van-popup v-model="showsdLlPicker" position="bottom">-->
<!--                <van-picker-->
<!--                        show-toolbar-->
<!--                        :columns="shangyelanlilv"-->
<!--                        @confirm="sdLlConfirm"-->
<!--                        @cancel="showsdLlPicker = false"-->
<!--                />-->
<!--            </van-popup>-->
        </div>
        <!--        <div v-show="formData.type != '商业贷款'">-->
        <!--            <h2 class="van-doc-demo-block__title">公积金贷款</h2>-->
        <!--            <van-field-->
        <!--                    input-align="right"-->
        <!--                    v-model="formData.gjjMoney"-->
        <!--                    name="gjjMoney"-->
        <!--                    label="贷款金额"-->
        <!--                    placeholder="贷款金额"-->
        <!--                    @input="gjjMoneyChange"-->
        <!--            >-->
        <!--                <template #right-icon>-->
        <!--                    万-->
        <!--                </template>-->
        <!--            </van-field>-->
        <!--            <van-field-->
        <!--                    input-align="right"-->
        <!--                    readonly-->
        <!--                    clickable-->
        <!--                    name="gjjYear"-->
        <!--                    :value="formData.gjjYear"-->
        <!--                    label="贷款年限"-->
        <!--                    placeholder="贷款年限"-->
        <!--                    @click="showgjjYearPicker = true"-->
        <!--            >-->
        <!--                <template #right-icon>-->
        <!--                    <van-icon name="arrow"/>-->
        <!--                </template>-->
        <!--            </van-field>-->
        <!--            <van-popup v-model="showgjjYearPicker" position="bottom">-->
        <!--                <van-picker-->
        <!--                        show-toolbar-->
        <!--                        :columns="loanyear"-->
        <!--                        @confirm="gjjYearConfirm"-->
        <!--                        @cancel="showgjjYearPicker = false"-->
        <!--                />-->
        <!--            </van-popup>-->
        <!--            <van-field-->
        <!--                    input-align="right"-->
        <!--                    readonly-->
        <!--                    clickable-->
        <!--                    name="gjjLl"-->
        <!--                    :value="gjjLlText"-->
        <!--                    label="贷款利率"-->
        <!--                    placeholder="贷款利率"-->
        <!--                    @click="showgjjLlPicker = true"-->
        <!--            >-->
        <!--                <template #right-icon>-->
        <!--                    <van-icon name="arrow"/>-->
        <!--                </template>-->
        <!--            </van-field>-->
        <!--            <van-popup v-model="showgjjLlPicker" position="bottom">-->
        <!--                <van-picker-->
        <!--                        show-toolbar-->
        <!--                        :columns="gjjlanlilv"-->
        <!--                        @confirm="gjjLlConfirm"-->
        <!--                        @cancel="showgjjLlPicker = false"-->
        <!--                />-->
        <!--            </van-popup>-->
        <!--        </div>-->
    </div>
</template>

<script>
    export default {
        name: 'jisuan',
        data() {
            return {
                formData: {
                    top: 'benxi',
                    type: '商业贷款',
                    sdMoney: '',
                    sdYear: 20,
                    sdLl: '',
                    gjjMoney: '',
                    gjjYear: 20,
                    gjjLl: '',
                },
                sdLlText: '',
                gjjLlText: '',
                detailShow: false,
                showtypePicker: false,
                showsdYearPicker: false,
                showsdLlPicker: false,
                showgjjYearPicker: false,
                showgjjLlPicker: false,
                typeList: ['商业贷款', '公积金贷款', '组合贷款',],
                loanyear: [
                    {
                        text: 1,
                        select: false
                    },
                    {
                        text: 2,
                        select: false
                    },
                    {
                        text: 3,
                        select: false
                    },
                    {
                        text: 4,
                        select: false
                    },
                    {
                        text: 5,
                        select: false
                    },
                    {
                        text: 6,
                        select: false
                    },
                    {
                        text: 7,
                        select: false
                    },
                    {
                        text: 8,
                        select: false
                    },
                    {
                        text: 9,
                        select: false
                    },
                    {
                        text: 10,
                        select: false
                    },
                    {
                        text: 11,
                        select: false
                    },
                    {
                        text: 12,
                        select: false
                    },
                    {
                        text: 13,
                        select: false
                    },
                    {
                        text: 14,
                        select: false
                    },
                    {
                        text: 15,
                        select: false
                    },
                    {
                        text: 16,
                        select: false
                    },
                    {
                        text: 17,
                        select: false
                    },
                    {
                        text: 18,
                        select: false
                    },
                    {
                        text: 19,
                        select: false
                    },
                    {
                        text: 20,
                        select: true
                    },
                    {
                        text: 21,
                        select: false
                    },
                    {
                        text: 22,
                        select: false
                    },
                    {
                        text: 23,
                        select: false
                    },
                    {
                        text: 24,
                        select: false
                    },
                    {
                        text: 25,
                        select: false
                    },
                    {
                        text: 26,
                        select: false
                    },
                    {
                        text: 27,
                        select: false
                    },
                    {
                        text: 28,
                        select: false
                    },
                    {
                        text: 29,
                        select: false
                    },
                    {
                        text: 30,
                        select: false
                    }
                ],
                shangdaiLilv: [
                    {
                        year: 1,
                        lilv: "0.0435"
                    },
                    {
                        year: 2,
                        lilv: "0.0475"
                    },
                    {
                        year: 6,
                        lilv: "0.049"
                    }
                ],
                shangdaiSelect: [
                    {
                        lilv: 1,
                        name: "基准利率"
                    },
                    {
                        lilv: 1.1,
                        name: "1.1倍利率"
                    },
                    {
                        lilv: 1.15,
                        name: "1.15倍利率"
                    },
                    {
                        lilv: 1.2,
                        name: "1.2倍利率"
                    },
                    {
                        lilv: 1.3,
                        name: "1.3倍利率"
                    },
                    {
                        lilv: 0.9,
                        name: "9折利率"
                    },
                    {
                        lilv: 0.85,
                        name: "85折利率"
                    },
                    {
                        lilv: 0.8,
                        name: "8折利率"
                    },
                    {
                        lilv: 0.7,
                        name: "7折利率"
                    }
                ],
                gjjLilv: [
                    {
                        year: 1,
                        lilv: "0.0275"
                    },
                    {
                        year: 6,
                        lilv: "0.0325"
                    }
                ],
                gjjSelect: [
                    {
                        lilv: 1,
                        name: "基准利率"
                    },
                    {
                        lilv: 1.1,
                        name: "1.1倍利率"
                    },
                    {
                        lilv: 1.15,
                        name: "1.15倍利率"
                    },
                    {
                        lilv: 1.2,
                        name: "1.2倍利率"
                    },
                    {
                        lilv: 1.3,
                        name: "1.3倍利率"
                    },
                    {
                        lilv: 0.9,
                        name: "9折利率"
                    },
                    {
                        lilv: 0.85,
                        name: "85折利率"
                    },
                    {
                        lilv: 0.8,
                        name: "8折利率"
                    },
                    {
                        lilv: 0.7,
                        name: "7折利率"
                    }
                ],
                shangyelanlilv: [],
                gjjlanlilv: [],
                typethmlUrl: '', //等额本息还款详情跳转
                cal_price_hook: '0.00',
                htotalnum: '0.00万',
                htotallixinum: '0.00万',
                cal_dijianprice: '0.00',
            };
        },
        created() {
            this.lilvCal(this.formData.sdYear, this.shangdaiLilv, this.shangdaiSelect, "shangyelanlilv");
            this.formData.sdLl = this.shangyelanlilv[0].value;
            this.sdLlText = this.shangyelanlilv[0].value * 100;
            // this.sdLlText = this.shangyelanlilv[0].text;
            this.lilvCal(this.formData.gjjYear, this.gjjLilv, this.gjjSelect, "gjjlanlilv");
            this.formData.gjjLl = this.gjjlanlilv[0].value;
            this.gjjLlText = this.gjjlanlilv[0].text;
        },
        methods: {
            //商贷年利率变化计算
            sdYearConfirm(value) {
                this.formData.sdYear = value.text;
                this.lilvCal(this.formData.sdYear, this.shangdaiLilv, this.shangdaiSelect, "shangyelanlilv");
                if (this.formData.type == "商业贷款") {
                    this.resultSd();
                }
                if (this.formData.type == "组合贷款") {
                    this.resuleZh();
                }
                this.showsdYearPicker = false;
            },
            gjjYearConfirm(value) {
                this.formData.gjjYear = value.text;
                this.lilvCal(this.formData.gjjYear, this.gjjLilv, this.gjjSelect, "gjjlanlilv");
                if (this.formData.type == "公积金贷款") {
                    this.resultGjj();
                }
                if (this.formData.type == "组合贷款") {
                    this.resuleZh();
                }
                this.showgjjYearPicker = false;
            },
            sdLlConfirm(value) {
                this.sdLlText = value.text;
                this.formData.sdLl = value.value;
                if (this.formData.type == "商业贷款") {
                    this.resultSd();
                }
                if (this.formData.type == "组合贷款") {
                    this.resuleZh();
                }
                this.showsdLlPicker = false;
            },
            sdLlChange() {
                this.formData.sdLl = this.sdLlText / 100;
                if (this.formData.type == "商业贷款") {
                    this.resultSd();
                }
                if (this.formData.type == "组合贷款") {
                    this.resuleZh();
                }
            },
            gjjLlConfirm(value) {
                this.gjjLlText = value.text;
                this.formData.gjjLl = value.value;
                if (this.formData.type == "公积金贷款") {
                    this.resultGjj();
                }
                if (this.formData.type == "组合贷款") {
                    this.resuleZh();
                }
                this.showgjjLlPicker = false;
            },
            navClick(value) {
                this.formData.top = value;
                if (value == "benxi") {
                    //执行本息计算逻辑 本息type 1,本金type 2
                    this.checkLanType("1")
                    // //详情页面
                    // if ($(this).hasClass("inner_bx")) {
                    //     _this.resultBind("1");
                    // }

                } else if (value == "benjin") {
                    //执行本金计算逻辑
                    this.checkLanType("2")
                    //详情页面
                    // if ($(this).hasClass("inner_bj")) {
                    //     _this.resultBind("2");
                    // }
                }
            },
            checkLanType: function (type, sdnum, gjjnum) {
                if (this.formData.type == "商业贷款") {
                    this.shangdaiData(type, sdnum);
                } else if (this.formData.type == "公积金贷款") {
                    this.gjjData(type, gjjnum);
                } else if (this.formData.type == "组合贷款") {
                    this.zuheData(type, sdnum, gjjnum);
                }
            },
            typeConfirm(value) {
                this.formData.type = value;
                if (value == "商业贷款") {
                    //执行计算逻辑
                    this.resultSd();

                } else if (value == "公积金贷款") {
                    //执行计算逻辑
                    this.resultGjj();

                } else if (value == "组合贷款") {
                    //执行计算逻辑
                    this.resuleZh();
                }
                this.showtypePicker = false;
            },
            sdMoneyChange(value) {
                let enterkey = value;
                let type = "";
                this.formData.top == "benxi" ? type = "1" : type = "2";
                // console.log("商贷计算逻辑");
                this.checkLanType(type, enterkey, "");
                if (enterkey.trim() != "" && !isNaN(enterkey) && enterkey > 0) {
                    this.detailShow = true;
                } else {
                    this.detailShow = false;
                }
            },
            gjjMoneyChange(value) {
                let enterkey = value;
                let type = "";
                this.formData.top == "benxi" ? type = "1" : type = "2";
                // console.log("公积金逻辑")
                this.checkLanType(type, "", enterkey);

                if (enterkey.trim() != "" && !isNaN(enterkey) && enterkey > 0) {
                    this.detailShow = true;
                } else {
                    this.detailShow = false;
                }
            },
            toDetail() {
                if (this.detailShow) this.$router.push(this.typethmlUrl);
            },
            resultSd: function () {
                let type = "";
                this.formData.top == "benxi" ? type = "1" : type = "2";
                this.shangdaiData(type);
            },
            resuleZh: function () {
                let type = "";
                this.formData.top == "benxi" ? type = "1" : type = "2";
                this.zuheData(type);
            },
            resultGjj: function () {
                let type = "";
                this.formData.top == "benxi" ? type = "1" : type = "2";
                this.gjjData(type);
            },
            gjjData: function (type, num) {
                num = num || this.formData.gjjMoney;
                let year = this.formData.gjjYear;
                let lilv = this.formData.gjjLl;
                //结果地址传参数
                this.navigateUrl(type, "2", "", num, "", year, "", lilv);
                //调用公积金计算公式
                let resobj = this.singleDk(type, num, year, lilv);
                //console.log(resobj);
                this.domOperates(type, resobj);
            },
            zuheData: function (type, sdnum, gjjnum) {
                sdnum = sdnum || this.formData.sdMoney;
                gjjnum = gjjnum || this.formData.gjjMoney;
                let sdyear = this.formData.sdYear;
                let sdlilv = this.formData.sdLl;
                let gjjyear = this.formData.gjjYear;
                let gjjlilv = this.formData.gjjLl;
                //结果地址传参数
                this.navigateUrl(type, "3", sdnum, gjjnum, sdyear, gjjyear, sdlilv, gjjlilv);
                //调用组合贷款计算公式
                let resobj = this.zuhe(type, sdnum, gjjnum, sdyear, gjjyear, sdlilv, gjjlilv);
                //console.log(resobj);
                this.domOperates(type, resobj);
            },
            shangdaiData: function (type, num) {
                num = num || this.formData.sdMoney;
                let year = this.formData.sdYear;
                let lilv = this.formData.sdLl;
                //结果地址传参数
                this.navigateUrl(type, "1", num, "", year, "", lilv, "");
                //调用商贷计算公式
                let resobj = this.singleDk(type, num, year, lilv);
                this.domOperates(type, resobj);

            },
            navigateUrl: function (a_type, a_loantype, a_sdnum, a_gjjnum, a_sdyear, a_gjjyear, a_sdlilv, a_gjjlilv) {
                let type = a_type || "1";
                let loantype = a_loantype || "1";
                let sdnum = a_sdnum || "";
                let gjjnum = a_gjjnum || "";
                let sdyear = a_sdyear || "";
                let gjjyear = a_gjjyear || "";
                let sdlilv = a_sdlilv || "";
                let gjjlilv = a_gjjlilv || "";
                let url = '';
                if (loantype == "1") {
                    url = {
                        path: '/detail',
                        query: {
                            type,
                            sdnum,
                            sdyear,
                            sdlilv,
                            loantype: 1
                        }
                    }
                    // url = '?type=' + type + '&sdnum=' + sdnum + '&sdyear=' + sdyear + '&sdlilv=' + sdlilv + '&loantype=1';
                } else if (loantype == "2") {
                    url = {
                        path: '/detail',
                        query: {
                            type,
                            gjjnum,
                            gjjyear,
                            gjjlilv,
                            loantype: 2
                        }
                    }
                    // url = '/detail?type=' + type + '&gjjnum=' + gjjnum + '&gjjyear=' + gjjyear + '&gjjlilv=' + gjjlilv + '&loantype=2';
                } else if (loantype == "3") {
                    url = {
                        path: '/detail',
                        query: {
                            type,
                            sdnum,
                            gjjnum,
                            sdyear,
                            gjjyear,
                            sdlilv,
                            gjjlilv,
                            loantype: 3
                        }
                    }
                    // url = '/detail?type=' + type + '&sdnum=' + sdnum + '&gjjnum=' + gjjnum + '&sdyear=' + sdyear + '&gjjyear=' + gjjyear + '&sdlilv=' + sdlilv + '&gjjlilv=' + gjjlilv + '&loantype=3';
                }
                this.typethmlUrl = url;
            },
            formatCurrency: function (num) { //将数值四舍五入(保留2位小数)后格式化成金额形式
                num = num.toString().replace(/\$|,/g, '');
                if (isNaN(num))
                    num = "0";
                let sign = (num == (num = Math.abs(num)));
                num = Math.floor(num * 100 + 0.50000000001);
                let cents = num % 100;
                num = Math.floor(num / 100).toString();
                if (cents < 10)
                    cents = "0" + cents;
                for (let i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
                    num = num.substring(0, num.length - (4 * i + 3)) + ',' +
                        num.substring(num.length - (4 * i + 3));
                return (((sign) ? '' : '-') + num + '.' + cents);
            },
            domOperates: function (type, data) {
                let yuegong = this.formatCurrency(data.yuegong);
                let totalPrice = this.formatCurrency(data.totalPrice / 10000); //万元转换
                let totalLixi = this.formatCurrency(data.totalLixi / 10000); //万元转换
                this.cal_price_hook = yuegong;
                this.htotalnum = totalPrice + "万";
                this.htotallixinum = totalLixi + "万";
                if (type == "2") {
                    this.cal_dijianprice = this.formatCurrency(data.yuegongdijian)
                }
            },
            lilvCal: function (year, lilv, selectcontent, id) {
                let getLilv = "";
                lilv.forEach(function (item) {
                    if (year >= item.year) {
                        getLilv = item.lilv;
                    }
                })
                let callilv = selectcontent.map(function (item) {
                    return {
                        value: (item.lilv * getLilv).toFixed(4),
                        text: item.name
                    }
                });
                this[id] = callilv;
            },
            //商贷-公积金贷款统一函数
            singleDk: function (type, num, year, lilv) {
                let _this = this;
                // type:1等额本息 2等额本金，num 贷款金额 year贷款年限，lilv：贷款基准利率
                if (type == 1) {
                    return _this.benxi(type, num, year, lilv)
                } else if (type == 2) {
                    return _this.benjin(type, num, year, lilv)
                }
            },
            //组合贷款计算
            zuhe: function (type, sdnum, gjjnum, sdyear, gjjyear, sdlilv, gjjlilv) {
                let _this = this,
                    year = sdyear > gjjyear ? sdyear : gjjyear;
                let mergemouthdataArray = '';
                if (type == 1) {
                    let sdObj = _this.benxi(type, sdnum, sdyear, sdlilv);
                    let gjjObj = _this.benxi(type, gjjnum, gjjyear, gjjlilv);
                    if (sdObj.mouthdataArray.length > gjjObj.mouthdataArray.length) {
                        mergemouthdataArray = sdObj.mouthdataArray.map(function (item, index) {
                            if (index < gjjObj.mouthdataArray.length) {
                                return {
                                    monthName: item.monthName,
                                    yuelixi: item.yuelixi + gjjObj.mouthdataArray[index].yuelixi,
                                    yuebenjin: item.yuebenjin + gjjObj.mouthdataArray[index].yuebenjin,
                                    leftFund: item.leftFund + gjjObj.mouthdataArray[index].leftFund
                                }
                            } else {
                                return {
                                    monthName: item.monthName,
                                    yuelixi: item.yuelixi,
                                    yuebenjin: item.yuebenjin,
                                    leftFund: item.leftFund
                                }
                            }

                        })
                    } else {
                        mergemouthdataArray = gjjObj.mouthdataArray.map(function (item, index) {
                            if (index < sdObj.mouthdataArray.length) {
                                return {
                                    monthName: item.monthName,
                                    yuelixi: item.yuelixi + sdObj.mouthdataArray[index].yuelixi,
                                    yuebenjin: item.yuebenjin + sdObj.mouthdataArray[index].yuebenjin,
                                    leftFund: item.leftFund + sdObj.mouthdataArray[index].leftFund
                                }
                            } else {
                                return {
                                    monthName: item.monthName,
                                    yuelixi: item.yuelixi,
                                    yuebenjin: item.yuebenjin,
                                    leftFund: item.leftFund
                                }
                            }
                        })
                    }
                    return {
                        yuegong: sdObj.yuegong + gjjObj.yuegong,
                        totalLixi: sdObj.totalLixi + gjjObj.totalLixi,
                        totalPrice: sdObj.totalPrice + gjjObj.totalPrice,
                        mouthdataArray: mergemouthdataArray,
                        totalDknum: parseFloat(sdObj.totalDknum) + parseFloat(gjjObj.totalDknum),
                        year: year
                    }

                } else if (type == 2) {
                    let sdObj = _this.benjin(type, sdnum, sdyear, sdlilv);
                    let gjjObj = _this.benjin(type, gjjnum, gjjyear, gjjlilv);
                    if (sdObj.mouthdataArray.length > gjjObj.mouthdataArray.length) {
                        mergemouthdataArray = sdObj.mouthdataArray.map(function (item, index) {
                            if (index < gjjObj.mouthdataArray.length) {
                                return {
                                    monthName: item.monthName,
                                    yuelixi: item.yuelixi + gjjObj.mouthdataArray[index].yuelixi,
                                    yuebenjin: item.yuebenjin + gjjObj.mouthdataArray[index].yuebenjin,
                                    leftFund: item.leftFund + gjjObj.mouthdataArray[index].leftFund
                                }
                            } else {
                                return {
                                    monthName: item.monthName,
                                    yuelixi: item.yuelixi,
                                    yuebenjin: item.yuebenjin,
                                    leftFund: item.leftFund
                                }
                            }

                        })
                    } else {
                        mergemouthdataArray = gjjObj.mouthdataArray.map(function (item, index) {
                            if (index < sdObj.mouthdataArray.length) {
                                return {
                                    monthName: item.monthName,
                                    yuelixi: item.yuelixi + sdObj.mouthdataArray[index].yuelixi,
                                    yuebenjin: item.yuebenjin + sdObj.mouthdataArray[index].yuebenjin,
                                    leftFund: item.leftFund + sdObj.mouthdataArray[index].leftFund
                                }
                            } else {
                                return {
                                    monthName: item.monthName,
                                    yuelixi: item.yuelixi,
                                    yuebenjin: item.yuebenjin,
                                    leftFund: item.leftFund
                                }
                            }
                        })
                    }
                    return {
                        yuegong: sdObj.yuegong + gjjObj.yuegong,
                        totalLixi: sdObj.totalLixi + gjjObj.totalLixi,
                        totalPrice: sdObj.totalPrice + gjjObj.totalPrice,
                        yuegongdijian: sdObj.yuegongdijian + gjjObj.yuegongdijian,
                        totalDknum: parseFloat(sdObj.totalDknum) + parseFloat(gjjObj.totalDknum),
                        year: year,
                        mouthdataArray: mergemouthdataArray
                    }
                }

            },
            //等额本息计算
            benxi: function (type, num, year, lilv) {
                //每月月供额=〔贷款本金×月利率×(1＋月利率)＾还款月数〕÷〔(1＋月利率)＾还款月数-1〕
                let mouth = parseInt(year) * 12,
                    mouthlilv = parseFloat(lilv) / 12,
                    dknum = parseFloat(num) * 10000;
                //每月月供
                let yuegong = (dknum * mouthlilv * Math.pow((1 + mouthlilv), mouth)) / (Math.pow((1 + mouthlilv), mouth) - 1);
                //总利息=还款月数×每月月供额-贷款本金
                let totalLixi = mouth * yuegong - dknum;
                //还款总额 总利息+贷款本金
                let totalPrice = totalLixi + dknum,
                    leftFund = totalLixi + dknum;

                //循环月份
                let mouthdataArray = [],
                    nowmouth = new Date().getMonth(),
                    realmonth = 0;

                for (let i = 1; i <= mouth; i++) {
                    realmonth = nowmouth + i;
                    let yearlist = Math.floor(i / 12);

                    realmonth = realmonth - 12 * yearlist;

                    if (realmonth > 12) {
                        realmonth = realmonth - 12
                    }
                    //console.log(realmonth)
                    //每月应还利息=贷款本金×月利率×〔(1+月利率)^还款月数-(1+月利率)^(还款月序号-1)〕÷〔(1+月利率)^还款月数-1〕
                    let yuelixi = dknum * mouthlilv * (Math.pow((1 + mouthlilv), mouth) - Math.pow((1 + mouthlilv), i - 1)) / (Math.pow((1 + mouthlilv), mouth) - 1);
                    //每月应还本金=贷款本金×月利率×(1+月利率)^(还款月序号-1)÷〔(1+月利率)^还款月数-1〕
                    let yuebenjin = dknum * mouthlilv * Math.pow((1 + mouthlilv), i - 1) / (Math.pow((1 + mouthlilv), mouth) - 1);
                    leftFund = leftFund - (yuelixi + yuebenjin);
                    if (leftFund < 0) {
                        leftFund = 0
                    }
                    mouthdataArray[i - 1] = {
                        monthName: realmonth + "月",
                        yuelixi: yuelixi,
                        yuebenjin: yuebenjin,
                        //剩余还款
                        leftFund: leftFund
                    }
                }
                return {
                    yuegong: yuegong,
                    totalLixi: totalLixi,
                    totalPrice: totalPrice,
                    mouthdataArray: mouthdataArray,
                    totalDknum: num,
                    year: year
                };
            },
            //等额本金计算
            benjin: function (type, num, year, lilv) {
                let mouth = parseInt(year) * 12,
                    mouthlilv = parseFloat(lilv) / 12,
                    dknum = parseFloat(num) * 10000,
                    yhbenjin = 0; //首月还款已还本金金额是0
                //每月应还本金=贷款本金÷还款月数
                let everymonthyh = dknum / mouth
                //每月月供额=(贷款本金÷还款月数)+(贷款本金-已归还本金累计额)×月利率
                let yuegong = everymonthyh + (dknum - yhbenjin) * mouthlilv;
                //每月月供递减额=每月应还本金×月利率=贷款本金÷还款月数×月利率
                let yuegongdijian = everymonthyh * mouthlilv;
                //总利息=〔(总贷款额÷还款月数+总贷款额×月利率)+总贷款额÷还款月数×(1+月利率)〕÷2×还款月数-总贷款额
                let totalLixi = ((everymonthyh + dknum * mouthlilv) + dknum / mouth * (1 + mouthlilv)) / 2 * mouth - dknum;
                //还款总额 总利息+贷款本金
                let totalPrice = totalLixi + dknum,
                    leftFund = totalLixi + dknum;

                //循环月份
                let mouthdataArray = [],
                    nowmouth = new Date().getMonth(),
                    realmonth = 0;

                for (let i = 1; i <= mouth; i++) {
                    realmonth = nowmouth + i;
                    let yearlist = Math.floor(i / 12);

                    realmonth = realmonth - 12 * yearlist;

                    if (realmonth > 12) {
                        realmonth = realmonth - 12
                    }
                    yhbenjin = everymonthyh * (i - 1);
                    let yuebenjin = everymonthyh + (dknum - yhbenjin) * mouthlilv;
                    //每月应还利息=剩余本金×月利率=(贷款本金-已归还本金累计额)×月利率
                    let yuelixi = (dknum - yhbenjin) * mouthlilv;
                    leftFund = leftFund - yuebenjin;
                    if (leftFund < 0) {
                        leftFund = 0
                    }
                    mouthdataArray[i - 1] = {
                        monthName: realmonth + "月",
                        yuelixi: yuelixi,
                        //每月本金
                        yuebenjin: everymonthyh,
                        //剩余还款
                        leftFund: leftFund
                    }
                }
                return {
                    yuegong: yuegong,
                    totalLixi: totalLixi,
                    totalPrice: totalPrice,
                    yuegongdijian: yuegongdijian,
                    mouthdataArray: mouthdataArray,
                    totalDknum: num,
                    year: year
                }

            }

        },
        components: {}
    }
</script>
<style scoped lang="less">
    .cal_top {
        background: linear-gradient(to left, #09f77b, #07c160);
        color: #fff;
        font-size: 12px;
        padding-top: 1px;

        .cal_nav {
            width: 180px;
            height: 28px;
            margin: 15px auto 0;
            display: flex;

            .cal_changenav {
                width: 50%;
                color: #07c160;
                text-align: center;
                line-height: 28px;
                border: 1px solid #fff;
                background-color: #fff;

                &:first-child {
                    border-radius: 6px 0 0 6px;
                    border-right: 0;
                }

                &:last-child {
                    border-radius: 0 6px 6px 0;
                }

                &.active_nav {
                    background-color: #07c160;
                    color: #fff;
                }
            }
        }

        .cal_result {
            margin-top: 30px;
            padding: 0 15px;
            height: 100px;

            .cal_resultli {
                .cal_title {
                    margin-bottom: 5px;
                }

                .cal_price {
                    font-size: 30px;
                    color: #fff;
                    width: 100%;
                    overflow: hidden;
                    display: block;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    height: 34px;
                }

                .cal_hktotal {
                    width: 100%;
                    border-right: 1px solid #fff;
                    margin-top: 10px;
                    height: 20px;
                    line-height: 20px;
                    color: #fff;
                    overflow: hidden;
                    display: block;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    .htotalnum {
                        font-size: 16px;
                    }
                }

                &.right_resultli {
                    text-indent: 10px;

                    .cal_hktotal {
                        border-right: 0 none;

                    }
                }
            }
        }

        .cal_benxiinfo {
            margin: 0 15px;
            border-top: 1px solid #fff;
            font-size: 12px;
            line-height: 40px;

            .cal_bxfont {
                color: #fff;
            }
        }
    }
</style>
